import {Footer, Whatsapp} from '../container';
import {Navbar} from '../components/';
import React from "react";
import {Outlet} from "react-router-dom";

export default function Root() {
  return (
      <>
        <Navbar/>
        <Outlet/>
        <Whatsapp/>
        <Footer/>
      </>
  );
}