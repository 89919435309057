import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import wishal from "./wishal.jpeg";
const Whatsapp = () => {
  return (
    <FloatingWhatsApp
      accountName="Masterbuilds"
      phoneNumber="+31618582082"
      statusMessage="Dit is geen bot!"
      chatMessage="Vraag maar raak."
      placeholder="Type hier uw bericht..."
      allowClickAway="true"
      avatar={wishal}
    />
  );
};

export default Whatsapp;
