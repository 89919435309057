import React from "react";

import "./Footer.scss";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer">
                    <table class="contact-block">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Mobiel</strong>
                            </td>
                            <td>
                                <a href="callto:0618582082">06 18 58 20 82</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>E-mail</strong>
                            </td>
                            <td>
                                <a href="mailto:info@masterbuilds.nl">info@masterbuilds.nl</a>
                            </td>
                        </tr>
                        <tr>
                            <td>KvK</td>
                            <td>93061188</td>
                        </tr>
                        <tr>
                            <td>BTW-nummer</td>
                            <td>NL004995499B82</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p className="footer__branding">&copy; {new Date().getFullYear()} Masterbuilds</p>
            </footer>
        </>
    );
};

export default Footer;
