import React, {useRef, useState} from "react";

import "./Form.scss";
import emailjs from "@emailjs/browser";
import {motion} from "framer-motion";
import {useFormik} from 'formik';
import * as Yup from 'yup';

const Request = () => {
  const form = useRef();
  const [redirect, setRedirect] = useState(false);

  const formik = useFormik({
    initialValues: {
      Name: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema: Yup.object({
      Name: Yup.string().required('Verplicht!'),
      email: Yup.string().email('Onjuist email address').required('Verplicht'),
      message: Yup.string().required('Verplicht!'),
    }),
    onSubmit: (values, actions) => {
      emailjs.send('service_2y1kut3', 'template_2f7v0bu', values, {
        publicKey: 'dv4uUtiRQBfdZhzok',
      })
      setRedirect(true);
    },
  });

  return (
      <section className="form">
        <motion.div
            className="card-container"
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            viewport={{amount: 0.8}}
        >
          <div className="splash"/>
          <motion.div className="card">
            <div className="form__request">
              <div className="form__body">
                <div className="form__body__item left">
                  <h2 className="form__title">
                    <span>Benieuwd of we wat voor jou kunnen </span>
                    <span>betekenen?</span>
                  </h2>
                  <div className="app-contact">Gemiddelde reactietijd is 20 minuten</div>
                </div>
                <div className="form__body__item">
                  {!redirect ?
                      <form ref={form} onSubmit={formik.handleSubmit}>
                        <div className="app-form">
                          <div className="app-form-group">
                            <input
                                id="firstName"
                                className="app-form-control"
                                type="text"
                                placeholder="Name*"
                                {...formik.getFieldProps('Name')}
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                                <span className="form__body__item-error">{formik.errors.Name}</span>
                            ) : null}
                          </div>
                          <div className="app-form-group">
                            <input className="app-form-control" name="email"
                                   placeholder="EMAIL*"
                                   {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <span className="form__body__item-error">{formik.errors.email}</span>
                            ) : null}
                          </div>
                          <div className="app-form-group">
                            <input className="app-form-control"
                                   {...formik.getFieldProps('phone')}
                                   name="phone" placeholder="Telefoonnummer"/>
                          </div>
                          <div className="app-form-group message">
                        <textarea className="app-form-control" rows="6" name="message" placeholder="bericht"
                                  {...formik.getFieldProps('message')}
                        />
                            {formik.touched.message && formik.errors.message ? (
                                <span className="form__body__item-error">{formik.errors.message}</span>
                            ) : null}
                          </div>
                          <div className="app-form-group buttons">
                            <button disabled={formik.isSubmitting} type="submit" className="app-form-button"
                                    value="Send">Verstuur
                            </button>
                          </div>
                        </div>
                      </form> :
                      <motion.div
                          className={"form__thankyou"} initial={{opacity: 0}}
                          whileInView={{opacity: 1}}
                          viewport={{amount: 0.8}}
                          transition={{delay: .5}}

                      ><h2 className={"form__title"}>Bedankt we zullen zo snel mogelijk
                        contact met u nemen!</h2>
                      </motion.div>
                  }
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </section>
  );
};

export default Request;