import {Routes, Route} from "react-router-dom";
import Homepage from "./routes/Homepage";
import Root from "./routes/Root";
import Repairs from "./routes/Repairs";

export default function App() {
  return (
      <>
        <Routes>
          <Route path="/" element={<Root/>}>
            <Route index element={<Homepage/>}/>
            <Route path="reparaties" element={<Repairs/>}/>
          </Route>
        </Routes>
      </>
  );
}
