import {Header, Form} from '../container';


export default function Homepage() {


  return (
      <>
        <Header/>
        <Form/>
      </>
  );
}