export default function Repairs() {
  return (
      <>
        <div className="skills">
          <h1>Trage computer? Crashen?</h1>
          <p>
            Ons team van ervaren technici beschikt over de kennis en expertise om een breed scala
            aan computerproblemen aan te pakken, van hardwarefouten tot softwarestoringen en alles daartussenin. Of het
            nu
            gaat om het vervangen van een kapotte laptop scherm, het verwijderen van hardnekkige virussen of het
            oplossen
            van netwerkproblemen, wij staan klaar om jou te helpen.
            <br/>
            <br/>
            Wat ons onderscheidt, is onze toewijding aan klanttevredenheid en onze focus op het leveren van hoogwaardige
            service. We begrijpen hoe belangrijk jouw computer voor je is, of het nu gaat om werk, school of persoonlijk
            gebruik, en we streven ernaar om ervoor te zorgen dat je snel weer operationeel bent.
            <br/>
            <br/>
            Bovendien bieden we niet alleen snelle en betrouwbare reparaties, maar ook eerlijke prijzen en transparante
            dienstverlening. Je kunt erop vertrouwen dat je bij ons een eerlijke en nauwkeurige schatting krijgt van de
            kosten van de reparatie, zonder verrassingen achteraf.
          </p>
        </div>
      </>
  );
}