import React from "react";
import "./Header.scss";
import pc from './pc.png'
import logo from './wishal.jpeg'
import {motion} from "framer-motion";
import Wave from 'react-wavify'

const Header = () => {

  return (
      <section className="hero">
        <div className="row">
          <div className="col-left">
            <div className="carousel">
              <div className="image">
                <img src={logo} alt="potrait"/>
              </div>
              <div className="image">
                <img src={pc} alt="potrait"/>
              </div>
            </div>
          </div>
          <div className="col-right">
            <motion.div transition={{type: "spring", stiffness: 100}} className="col-right">
              <p className="col-right__description">
                Wij bouwen PC’s en workstations, perfect op maat.
                <br/>
                Everything is possible
              </p>
            </motion.div>
          </div>

        </div>
        <div className="divider">
          <div className="wave">

            <Wave fill='#D30000'
                  paused={false}
                  style={{display: 'flex'}}
                  options={{
                    height: 20,
                    amplitude: 30,
                    speed: 0.2,
                    points: 3
                  }}
            />

            <div className="slogan">
              <h2>
                Kies voor maatwerk en ervaar het verschil met een computer die echt is afgestemd op jouw
                individuele
                behoeften.
              </h2>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Header;
