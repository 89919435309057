import React from "react";
import {NavLink} from "react-router-dom";

import "./Navbar.scss";
import logo from "./../assets/logo.png";
import {motion, useScroll, useSpring} from "framer-motion";

export default function Navbar() {
  const {scrollYProgress} = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  return (
      <>
        <div className="top-nav">
          <div className="top-nav__highlight">
            Wij zijn bereikbaar op <a href="callto:0618582082">06 18 58 20 82</a>
          </div>
        </div>
        <header>
        </header>
        <nav>
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  <motion.img initial={{opacity: 0, scale: 0.5}}
                              animate={{opacity: 1, scale: 1}}
                              transition={{
                                duration: 0.3,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                  type: "spring",
                                  damping: 5,
                                  stiffness: 100,
                                  restDelta: 0.001
                                }
                              }} src={logo} className="logo" alt="" width="160px"/>
                </NavLink>
              </li>
              <li>
                <NavLink to="/reparaties" className={({isActive, isPending}) =>
                    isPending ? "pending" : isActive ? "active" : ""
                }>Reparaties</NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <motion.div className="progress-bar" style={{scaleX}}/>
      </>
  );
}
